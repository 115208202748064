import Footer from "components/footer";
import Header from "components/header";
import NotificationBar from "components/notification-bar";
import ProtectedRoute from "components/protected-route";
import ResetScroll from "components/reset-scroll";
import ScrollTop from "components/scroll-top";
import Archives from "pages/archives";
import Contact from "pages/contact";
import Dashboard from "pages/dashboard";
import Home from "pages/home";
import Login from "pages/login";
import NotFound from "pages/not-found";
import Show from "pages/show";
import Terms from "pages/terms";
import { FC, Fragment } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "styles/animations.scss";
import "styles/container.scss";

const RootLayout: FC = () => (
    <Fragment>
        <NotificationBar />
        <Header />
        <ResetScroll />
        <ScrollTop />
        <Outlet />
        <Footer />
    </Fragment>
);

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <RootLayout />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "show",
                    element: <Show />,
                },
                {
                    path: "archives",
                    element: <Archives />,
                },
                {
                    path: "contact",
                    element: <Contact />,
                },
                {
                    path: "terms",
                    element: <Terms />,
                },
                {
                    path: "login",
                    element: <Login />,
                },
                {
                    path: "dashboard",
                    element: (
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: "*",
                    element: <NotFound />,
                },
            ],
        },
    ],
    {
        future: {
            v7_relativeSplatPath: true,
        },
    },
);

const App: FC = () => {
    return <RouterProvider router={router} />;
};

export default App;
