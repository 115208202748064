const { NODE_ENV } = process.env;

/**
 * @function getTodayDate
 *
 * @description Returns the current date formatted.
 *
 * @returns The current date in the format 'YYYY-MM-DD'.
 */
export function getToday(): string {
    const today = new Date();
    return today.toISOString().split("T")[0];
}

export function logError(error: string): void {
    if (NODE_ENV !== "production") {
        console.error(error);
    }
}
