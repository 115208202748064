import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { useAuth } from "providers/authentication";
import { useTheme } from "providers/theme";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "styles/components/header.scss";

const Header: FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const { theme, toggleTheme } = useTheme();
    const { isAuthenticated } = useAuth();

    const headerRef = useRef<HTMLHeadElement>(null);
    const navigate = useNavigate();

    const toggleMenu = useCallback(() => setIsMenuOpen((prev) => !prev), []);
    const closeMenu = useCallback(() => setIsMenuOpen(false), []);

    const goToDashboard = () => {
        if (isAuthenticated) {
            navigate("/dashboard");
        } else {
            navigate("/login");
        }
        closeMenu();
    };
    const toggleThemeButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        toggleTheme();
    };

    useEffect(() => {
        const handleScroll = () => setIsScrolled(window.scrollY > 0);
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                headerRef.current &&
                !headerRef.current.contains(event.target as Node)
            ) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("click", handleOutsideClick);

        return () => {
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("click", handleOutsideClick);
        };
    }, []);

    return (
        <header
            className={`header ${isScrolled || isMenuOpen ? "header-scrolled" : ""}`}
            ref={headerRef}
        >
            <div className="header-container">
                <div className="header-item header-main">
                    <div className="header-title">
                        <Link
                            className="header-link"
                            to="/"
                            onClick={closeMenu}
                        >
                            Logeler Finka
                        </Link>
                    </div>
                    <div className="header-opener">
                        <label
                            className="header-opener-label"
                            htmlFor="header-opener-checkbox"
                        >
                            <div
                                className="header-opener-icon"
                                aria-hidden="true"
                            />
                        </label>
                        <input
                            type="checkbox"
                            className="header-opener-checkbox"
                            id="header-opener-checkbox"
                            checked={isMenuOpen}
                            onChange={toggleMenu}
                            aria-expanded={isMenuOpen}
                            aria-controls="header-nav"
                        />
                    </div>
                </div>

                <nav
                    className={`header-item header-nav ${isMenuOpen ? "header-nav-opened" : ""}`}
                >
                    <Link
                        className="header-nav-item header-link"
                        to="/show"
                        onClick={closeMenu}
                    >
                        Spectacle
                    </Link>
                    <Link
                        className="header-nav-item header-link"
                        to="/archives"
                        onClick={closeMenu}
                    >
                        Archives
                    </Link>
                    <Link
                        className="header-nav-item header-link"
                        to="/contact"
                        onClick={closeMenu}
                    >
                        Contact
                    </Link>
                </nav>

                <div
                    className={`header-item header-shortcuts ${isMenuOpen ? "header-shortcuts-opened" : ""}`}
                >
                    <button className="header-shortcut" onClick={goToDashboard}>
                        <UserIcon className="header-shortcut-icon" />
                    </button>
                    <button
                        className="header-shortcut"
                        onClick={toggleThemeButton}
                    >
                        {theme !== "light" ? (
                            <SunIcon className="header-shortcut-icon" />
                        ) : (
                            <MoonIcon className="header-shortcut-icon" />
                        )}
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
