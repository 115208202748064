import { FC } from "react";
import "styles/components/notification-bar.scss";

const NotificationBar: FC = () => {
    return (
        <div className="notification-bar">
            <p className="notification-bar-content">
                La saison 2024 est terminée. Rendez-vous à l'automne 2025 !
            </p>
        </div>
    );
};

export default NotificationBar;
