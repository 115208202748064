import App from "core/app";
import { AuthProvider } from "providers/authentication";
import { ThemeProvider } from "providers/theme";
import React from "react";
import ReactDOM from "react-dom/client";
import "styles/index.scss";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider>
                <App />
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>,
);
