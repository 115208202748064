import { Maybe, User, UserPermission, permissionHierarchy } from "core/models";

/**
 * @function isUserAllowed
 *
 * @description Checks if the user has the required permission to access a resource.
 *
 * @param user The user object.
 * @param min The minimum permission required.
 * @returns Whether the user has the required permission.
 */
export function isUserAllowed(user: Maybe<User>, min: UserPermission): boolean {
    if (!user) {
        return false;
    }
    return (
        permissionHierarchy.indexOf(user.permission) >=
        permissionHierarchy.indexOf(min)
    );
}
