import { FC, useEffect, useState } from "react";
import "styles/components/scroll-top.scss";

const ScrollTop: FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            setIsVisible(window.scrollY > 800);
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const resetScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

    return (
        <div
            className={`scroll-top ${isVisible ? "scroll-top-visible" : ""}`}
            onClick={resetScroll}
            aria-hidden={!isVisible}
        />
    );
};

export default ScrollTop;
