import { ReactComponent as Newspaper } from "assets/icons/newspaper.svg";
import HelmetWrapper from "components/helmet-wrapper";
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "styles/pages/home.scss";

const { REACT_APP_IMAGE_URL } = process.env;

const imageCount = 6;

const Home: FC = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imageCount);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="container">
            <HelmetWrapper
                title="Logeler Finka"
                description="Bienvenue sur le site du théâtre alsacien de Logelheim. Découvrez notre histoire et nos prochains événements."
            />

            <div className="home-welcome">
                <div className="home-welcome-slideshow">
                    {Array.from({ length: imageCount }, (_, index) => (
                        <div
                            className={`home-welcome-slide ${index !== currentIndex ? "" : "home-welcome-slide-active"}`}
                            key={index}
                        >
                            <img
                                src={`${REACT_APP_IMAGE_URL}/home-${index + 1}.webp`}
                                alt={`home-slide-${index + 1}`}
                            />
                        </div>
                    ))}
                </div>
                <h2 className="home-welcome-subtitle home-underline">
                    Le théâtre alsacien de Logelheim
                </h2>
                <p className="home-welcome-description">
                    La troupe de théâtre Logeler Finka vous présente chaque
                    année une nouvelle pièce de théatre en alsacien !
                </p>
            </div>

            <div className="home-show">
                <h2 className="home-show-title home-underline">
                    Spectacle 2024
                </h2>
                <p className="home-show-description">
                    Venez découvrir{" "}
                    <Link className="container-link" to="/show">
                        Nix Amore am Lago Maggiore
                    </Link>
                    . Une comédie hilarante de Bernd Gombold.
                </p>
                <a
                    className="home-show-newspaper"
                    href="https://www.dna.fr/culture-loisirs/2024/10/29/theatre-les-logeler-finka-nous-emmenent-au-lac-majeur"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Newspaper className="home-show-newspaper-icon" />
                </a>
            </div>
        </div>
    );
};

export default Home;
