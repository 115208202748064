import HelmetWrapper from "components/helmet-wrapper";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import "styles/pages/not-found.scss";

const NotFound: FC = () => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate("/");
    };

    return (
        <div className="container">
            <HelmetWrapper
                title="Page introuvable - Logeler Finka"
                description="La page recherchez n'existe pas."
            />

            <div className="not-found">
                <h1 className="not-found-title">404</h1>
                <h2 className="not-found-subtitle">Oups ! Vous êtes perdu.</h2>
                <p className="not-found-description">
                    La page que vous cherchez n'existe pas.
                </p>
                <button
                    className="container-button container-large-button"
                    type="button"
                    onClick={goHome}
                >
                    Poursuivre ma visite
                </button>
            </div>
        </div>
    );
};

export default NotFound;
