import Spinner from "components/spinner";
import { ProtectedRouteProps, SpinnerSize } from "core/models";
import { useAuth } from "providers/authentication";
import { useEffect, useRef } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
    const { checkAuthStatus, isAuthenticated, isLoading } = useAuth();
    const hasCheckedAuth = useRef(false);

    useEffect(() => {
        if (!hasCheckedAuth.current) {
            checkAuthStatus();
            hasCheckedAuth.current = true;
        }
    }, [checkAuthStatus, hasCheckedAuth]);

    if (isLoading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.Medium} />
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return children as JSX.Element;
};

export default ProtectedRoute;
