import { ReactComponent as WaveIcon } from "assets/icons/wave.svg";
import { FC } from "react";
import { Link } from "react-router-dom";
import "styles/components/footer.scss";

const { REACT_APP_VERSION } = process.env;

const Footer: FC = () => {
    const year = new Date().getFullYear();

    return (
        <footer className="footer">
            <div className="footer-wave">
                <WaveIcon />
            </div>
            <div className="footer-content">
                <Link className="footer-terms" to={"/terms"}>
                    &gt; Consulter les mentions légales
                </Link>
                <p className="footer-copyright">
                    Copyright &copy; {year} Logeler Finka. Tous droits réservés.
                    Version {REACT_APP_VERSION}
                </p>
            </div>
        </footer>
    );
};

export default Footer;
