import HelmetWrapper from "components/helmet-wrapper";
import Spinner from "components/spinner";
import { EventData, ShowEventData, SpinnerSize } from "core/models";
import { FC, Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "styles/pages/show.scss";
import { logError } from "utils/tools";

const { REACT_APP_API_URL, REACT_APP_IMAGE_URL } = process.env;

const Show: FC = () => {
    const [events, setEvents] = useState<ShowEventData>({});
    const [isListLoading, setIsListLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsListLoading(true);

            try {
                const response = await fetch(`${REACT_APP_API_URL}/events`);
                if (!response.ok) {
                    throw new Error("Failed to fetch events");
                }
                const data: EventData = await response.json();
                const today = new Date();

                today.setHours(0, 0, 0, 0);

                const sortedData = data
                    .map((event) => {
                        const eventDate = new Date(event.date);
                        eventDate.setHours(0, 0, 0, 0);

                        return {
                            ...event,
                            gone: today > eventDate,
                            dateObj: eventDate,
                        };
                    })
                    .sort((a, b) => a.dateObj.getTime() - b.dateObj.getTime());

                const groupedData = sortedData.reduce((accumulator, event) => {
                    const formattedDate = event.dateObj.toLocaleDateString(
                        "fr-FR",
                        {
                            day: "2-digit",
                            month: "2-digit",
                        },
                    );

                    if (!accumulator[event.weekday]) {
                        accumulator[event.weekday] = [];
                    }

                    accumulator[event.weekday].push({
                        ...event,
                        date: formattedDate,
                    });

                    return accumulator;
                }, {} as ShowEventData);

                setEvents(groupedData);
            } catch (error) {
                logError(
                    `An error occurred while fetching the events: ${error}`,
                );
            } finally {
                setIsListLoading(false);
            }
        };

        fetchData();
    }, []);

    const displayEventsTable = () => {
        if (isListLoading) {
            return <Spinner size={SpinnerSize.Medium} />;
        }

        if (Object.keys(events).length < 1) {
            return (
                <h2 className="show-table-caption show-table-empty">
                    Aucune date de représentation n'est disponible
                </h2>
            );
        }

        return (
            <Fragment>
                <h2 className="show-table-caption">
                    Dates de nos représentations
                </h2>
                <table className="show-table">
                    <thead>
                        <tr>
                            <th>
                                Vendredi
                                <br />
                                20h00
                            </th>
                            <th>
                                Samedi
                                <br />
                                20h00
                            </th>
                            <th>
                                Dimanche
                                <br />
                                14h30
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {["friday", "saturday", "sunday"].map((weekday) => (
                                <td key={weekday}>
                                    {events[weekday] &&
                                        events[weekday].map((event) => (
                                            <p
                                                key={event.id}
                                                className={
                                                    event.booked || event.gone
                                                        ? "show-event-booked"
                                                        : ""
                                                }
                                            >
                                                {event.date}
                                            </p>
                                        ))}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </Fragment>
        );
    };

    const toggleExpand = () => setIsExpanded((prev) => !prev);

    return (
        <div className="container">
            <HelmetWrapper
                title="Spectacle - Logeler Finka"
                description="Découvrez toutes les informations concernant notre dernier spectacle. Tarifs, réservations, dates et lieu des représentations."
            />

            <h1 className="container-title">Notre Spectacle</h1>
            <p className="container-description">
                Découvrez tous les détails de notre spectacle !
                <br />
                Si vous avez des questions, n'hésitez pas à{" "}
                <Link to="/contact" className="container-link">
                    nous contacter
                </Link>
                .
            </p>

            <section className="show-details">
                <div>
                    <img
                        className="show-poster"
                        src={`${REACT_APP_IMAGE_URL}/assets/poster.webp`}
                        alt="Spectacle"
                    />
                </div>
                <div className="show-pitch">
                    <h2>Ce qui vous attend sur scène</h2>
                    <button
                        className="show-pitch-expander"
                        onClick={toggleExpand}
                        aria-expanded={isExpanded}
                    >
                        {isExpanded ? "Lire moins" : "Lire plus"}
                    </button>
                    <p>
                        Teresa et son frère Angelo dirigent le charmant hôtel «
                        Amore Mio », situé au bord du lac Majeur. Angelo, malgré
                        son charme naturel, est souvent peu assidu, ce qui ne
                        passe pas inaperçu auprès des jeunes clients de l'hôtel.
                    </p>
                    {isExpanded ? (
                        <div>
                            <p>
                                Charlotte et Mathilde se retrouvent à « Amore
                                Mio » pour passer des vacances ensemble, bien
                                que leurs personnalités soient diamétralement
                                opposées : Charlotte, en quête de plaisir et
                                d'aventures romantiques, se distingue fortement
                                de Mathilde, arrivée par train avec une valise
                                contenant un secret pour le moins surprenant.
                            </p>
                            <p>
                                Leur séjour est également marqué par l'arrivée
                                d'Alice, de son mari Ernest, et de son frère
                                Oscar. Ce dernier se révèle être un atout
                                précieux pour Teresa, en trouvant une solution
                                efficace à un problème croissant :
                                l'infiltration des malfaisants Omeisa dans
                                l'hôtel.
                            </p>
                            <p>
                                Pendant ce temps, Ritchi, un trafiquant de
                                drogue, est déterminé à récupérer une valise
                                précieuse. Cependant, un petit micro dissimulé
                                dans la robe de Mathilde a enregistré une
                                conversation cruciale, attirant l'attention des
                                forces spéciales Anti-Drogue, qui sont désormais
                                sur le qui-vive.
                            </p>
                        </div>
                    ) : (
                        <p>...</p>
                    )}
                </div>
            </section>

            <section className="show-container">
                <h2 className="show-subtitle">Tarifs</h2>
                <p className="container-text">Tarif unique de 10€.</p>
                <p className="container-text">
                    Le paiement par chèque s'effectue par courrier à Logeler
                    Finka, 28 rue Grendel 68280 Logelheim.
                </p>
            </section>

            <section className="show-bubble">
                <p className="container-text show-bubble-text">
                    Les recettes de la première représentation seront reversées
                    à l'association{" "}
                    <a
                        className="container-link"
                        href="https://www.lechampdelacroix.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Champ de la Croix
                    </a>{" "}
                    à Orbey.
                </p>
            </section>

            <section className="show-container">
                <h2 className="show-subtitle">Réservations</h2>
                <p className="container-text">
                    Réservez vos places du lundi au samedi de 9h00 à 14h00 par
                    téléphone au{" "}
                    <a className="container-link" href="tel:+33666698677">
                        06 66 69 86 77
                    </a>{" "}
                    ou par email à{" "}
                    <a
                        href="mailto:logelerfinka@gmail.com"
                        className="container-link"
                    >
                        logelerfinka@gmail.com
                    </a>
                    .
                </p>
            </section>

            {displayEventsTable()}

            <section className="show-container">
                <h2 className="show-subtitle">Lieu</h2>
                <p className="container-text">
                    Les représentations ont lieu à la salle multi-activités de
                    Logelheim, 1 Rue Lazarre de Schwendi, 68280 Logelheim.
                    Parking gratuit disponible pour les spectateurs.{" "}
                    <a
                        className="container-link"
                        href="https://maps.app.goo.gl/V42XXm6LPXXaEUq9A"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Voir sur Google Maps
                    </a>
                    .
                </p>
                <p className="container-text">
                    En cas de petite faim, profitez de l'entracte pour savourer
                    nos rafraîchissements et délicieuses gourmandises.
                </p>
            </section>

            <figure className="show-location">
                <img
                    src={`${REACT_APP_IMAGE_URL}/assets/location.webp`}
                    alt="Salle Multi-activités de Logelheim"
                />
                <figcaption>
                    Salle multi-activités de Logelheim, prête à accueillir nos
                    représentations.
                </figcaption>
            </figure>
        </div>
    );
};

export default Show;
