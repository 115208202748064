import HelmetWrapper from "components/helmet-wrapper";
import Spinner from "components/spinner";
import Toast from "components/toast";
import {
    LoginFormData,
    Maybe,
    SpinnerSize,
    ToastData,
    ToastType,
} from "core/models";
import { useAuth } from "providers/authentication";
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import "styles/pages/login.scss";
import { isEmailValid } from "utils/validity";

const Login: FC = () => {
    const [formData, setFormData] = useState<LoginFormData>({
        email: "",
        password: "",
    });
    const [isLoginRequestLoading, setIsLoginRequestLoading] = useState(false);
    const [toastData, setToastData] = useState<Maybe<ToastData>>(null);

    const { checkAuthStatus, login, isAuthenticated, isLoading } = useAuth();
    const hasCheckedAuth = useRef(false);

    useEffect(() => {
        if (!hasCheckedAuth.current) {
            checkAuthStatus();
            hasCheckedAuth.current = true;
        }
    }, [checkAuthStatus, hasCheckedAuth]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = (): Maybe<string> => {
        if (!formData.email.trim() || !formData.password.trim()) {
            return "Tous les champs doivent être remplis";
        }

        if (!isEmailValid(formData.email)) {
            return "L'adresse email n'est pas valide";
        }

        return null;
    };

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoginRequestLoading(true);

        const errorMessage = validateForm();
        if (errorMessage) {
            setToastData({
                type: ToastType.Error,
                message: errorMessage,
            });
            setIsLoginRequestLoading(false);
            return;
        }

        const success = await login(formData.email, formData.password);
        if (!success) {
            setToastData({
                type: ToastType.Error,
                message: "Connexion impossible",
            });
        }

        setIsLoginRequestLoading(false);
    };

    if (isLoading) {
        return (
            <div className="loading-container">
                <Spinner size={SpinnerSize.Medium} />
            </div>
        );
    }

    if (isAuthenticated) {
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <div className="container">
            <HelmetWrapper
                title="Connexion"
                description="La page de connexion permet d'accéder à certaines ressources protégées."
            />

            <h1 className="container-title">Connexion</h1>
            <p className="container-description">
                Connectez-vous pour accéder à votre espace personnel.
            </p>

            <form className="login-form" onSubmit={submitForm} noValidate>
                <label className="login-form-label" htmlFor="email">
                    Identifiant
                </label>
                <input
                    className="login-form-input"
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                />

                <label className="login-form-label" htmlFor="password">
                    Mot de passe
                </label>
                <input
                    className="login-form-input"
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    value={formData.password}
                    onChange={handleChange}
                />

                <div className="login-form-separator" />

                <button
                    className="container-button container-small-button"
                    disabled={isLoginRequestLoading}
                >
                    Se connecter
                </button>
            </form>

            {toastData && (
                <Toast
                    message={toastData.message}
                    type={toastData.type}
                    onClose={() => setToastData(null)}
                />
            )}
            {isLoginRequestLoading && <Spinner size={SpinnerSize.Small} />}
        </div>
    );
};

export default Login;
