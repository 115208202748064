import { Theme, ThemeContextProps, ThemeProviderProps } from "core/models";
import { createContext, FC, useContext, useState } from "react";

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps);

export const ThemeProvider: FC<ThemeProviderProps> = ({ children }) => {
    const [theme, setTheme] = useState<Theme>(() => {
        const storagedTheme = localStorage.getItem("theme");

        return storagedTheme !== "dark" ? Theme.Light : Theme.Dark;
    });

    const toggleTheme = () => {
        const newTheme = theme !== "light" ? Theme.Light : Theme.Dark;
        setTheme(newTheme);

        document.documentElement.setAttribute("data-theme", newTheme);
        localStorage.setItem("theme", newTheme);

        const metaThemeColor = document.querySelector(
            "meta[name='theme-color']",
        );
        if (metaThemeColor) {
            metaThemeColor.setAttribute(
                "content",
                newTheme !== "dark" ? "#f9f6f8" : "#1b1f23",
            );
        }
    };

    return (
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => {
    const context = useContext(ThemeContext);

    if (!context) {
        throw new Error("useTheme must be used within a ThemeProvider");
    }

    return context;
};
