import { ReactComponent as RecycleBin } from "assets/icons/recycle-bin.svg";
import Spinner from "components/spinner";
import Toast from "components/toast";
import {
    DashboardEvent,
    DashboardEventData,
    Event,
    EventData,
    Maybe,
    SpinnerSize,
    ToastData,
    ToastType,
    UserPermission,
} from "core/models";
import { useAuth } from "providers/authentication";
import { FC, FormEvent, useEffect, useMemo, useState } from "react";
import "styles/pages/manager/events.scss";
import { isUserAllowed } from "utils/permission";
import { getToday, logError } from "utils/tools";

const { REACT_APP_API_URL } = process.env;

const weekdayTranslations = {
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche",
};

const EventsManager: FC<{ filter: string }> = ({ filter }) => {
    const [formDate, setFormDate] = useState(getToday());
    const [toastData, setToastData] = useState<Maybe<ToastData>>(null);
    const [events, setEvents] = useState<DashboardEventData>([]);
    const [isRequestLoading, setIsRequestLoading] = useState(false);
    const [isListLoading, setIsListLoading] = useState(true);
    const [selectedEventIndex, setSelectedEventIndex] = useState<number>(-1);
    const { protectedFetch, user } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await fetch(`${REACT_APP_API_URL}/events`);

                if (res.ok) {
                    const data: EventData = await res.json();
                    const today = new Date();

                    today.setHours(0, 0, 0, 0);

                    const processed: DashboardEventData = data
                        .map((event) => {
                            const eventDate = new Date(event.date);
                            eventDate.setHours(0, 0, 0, 0);

                            return {
                                ...event,
                                formattedDate: eventDate.toLocaleDateString(
                                    "fr-FR",
                                    {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                    },
                                ),
                                translatedWeekday:
                                    weekdayTranslations[event.weekday],
                                gone: today > eventDate,
                                dateObj: eventDate,
                            };
                        })
                        .sort(
                            (a, b) => a.dateObj.getTime() - b.dateObj.getTime(),
                        );

                    setEvents(processed);
                } else {
                    setToastData({
                        type: ToastType.Error,
                        message: "Impossible de récupérer les événements",
                    });
                }
            } catch (error) {
                logError(
                    `An error occurred while fetching the events: ${error}`,
                );
                setToastData({
                    type: ToastType.Error,
                    message: "Erreur lors de la récupération des événements",
                });
            } finally {
                setIsListLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        document.body.style.overflow =
            selectedEventIndex !== -1 ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [selectedEventIndex]);

    const filteredEvents = useMemo(() => {
        const lowerCaseFilter = filter.toLowerCase();
        return events.filter(
            (event) =>
                event.date.includes(lowerCaseFilter) ||
                event.weekday.toLowerCase().includes(lowerCaseFilter) ||
                event.translatedWeekday
                    .toLowerCase()
                    .includes(lowerCaseFilter) ||
                event.formattedDate.includes(lowerCaseFilter),
        );
    }, [filter, events]);

    if (!isUserAllowed(user, UserPermission.Manager)) {
        return null;
    }

    const validateForm = (): Maybe<string> => {
        if (!formDate) {
            return "La date est requise";
        }

        const day = new Date(formDate).getDay();
        if (![5, 6, 0].includes(day)) {
            return "La date doit être un vendredi, samedi ou dimanche";
        }

        if (events.some((e) => e.date === formDate)) {
            return "Un événement avec cette date existe déjà";
        }

        return null;
    };

    const createEvent = async (e: FormEvent) => {
        e.preventDefault();
        setIsRequestLoading(true);

        const errorMessage = validateForm();
        if (errorMessage) {
            setToastData({
                type: ToastType.Error,
                message: errorMessage,
            });
            setIsRequestLoading(false);
            return;
        }

        try {
            const res = await protectedFetch(`${REACT_APP_API_URL}/events`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ date: formDate }),
            });

            if (res.ok) {
                const data: Event = await res.json();
                const eventDate = new Date(formDate);
                const today = new Date();

                eventDate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);

                const newEvent: DashboardEvent = {
                    booked: false,
                    gone: today > eventDate,
                    date: formDate,
                    id: data.id,
                    weekday: data.weekday,
                    formattedDate: new Date(formDate).toLocaleDateString(
                        "fr-FR",
                        {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                        },
                    ),
                    translatedWeekday: weekdayTranslations[data.weekday],
                    dateObj: eventDate,
                };

                setFormDate(getToday());
                setEvents((prevEvents) =>
                    [...prevEvents, newEvent].sort(
                        (a, b) => a.dateObj.getTime() - b.dateObj.getTime(),
                    ),
                );
                setToastData({
                    type: ToastType.Success,
                    message: "Événement ajouté avec succès",
                });
            } else {
                setToastData({
                    type: ToastType.Error,
                    message: "Impossible d'ajouter l'événement",
                });
            }
        } catch (error) {
            logError(`An error occurred while creating the event: ${error}`);
            setToastData({
                type: ToastType.Error,
                message: "Erreur lors de la création de l'événement",
            });
        } finally {
            setIsRequestLoading(false);
        }
    };

    const updateEvent = async (id: number, booked: boolean) => {
        setIsRequestLoading(true);

        try {
            const res = await protectedFetch(
                `${REACT_APP_API_URL}/events/${id}`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ booked }),
                },
            );

            if (res.ok) {
                setEvents((prevEvents) =>
                    prevEvents.map((event) =>
                        event.id !== id ? event : { ...event, booked },
                    ),
                );
                setToastData({
                    type: ToastType.Success,
                    message: "Événement mis à jour avec succès",
                });
            } else {
                setToastData({
                    type: ToastType.Error,
                    message: "Impossible de mettre à jour l'événement",
                });
            }
        } catch (error) {
            logError(`An error occurred while updating the event: ${error}`);
            setToastData({
                type: ToastType.Error,
                message: "Erreur lors de la mise à jour de l'événement",
            });
        } finally {
            setIsRequestLoading(false);
        }
    };

    const deleteEvent = async (id: number) => {
        setIsRequestLoading(true);

        try {
            const res = await protectedFetch(
                `${REACT_APP_API_URL}/events/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );

            if (res.ok) {
                setEvents((prevEvents) =>
                    prevEvents.filter((event) => event.id !== id),
                );
                setToastData({
                    type: ToastType.Success,
                    message: "Événement supprimé avec succès",
                });
            } else {
                setToastData({
                    type: ToastType.Error,
                    message: "Impossible de supprimer l'événement",
                });
            }
        } catch (error) {
            logError(`An error occurred while deleting the event: ${error}`);
            setToastData({
                type: ToastType.Error,
                message: "Erreur lors de la suppression de l'événement",
            });
        } finally {
            setIsRequestLoading(false);
            setSelectedEventIndex(-1);
        }
    };

    const displayEventsList = () => {
        if (isListLoading) {
            return <Spinner size={SpinnerSize.Medium} />;
        }

        if (filteredEvents.length < 1) {
            return (
                <p className="dashboard-content-title">
                    Aucun événement disponible
                </p>
            );
        }

        return (
            <div className="dashboard-events">
                {filteredEvents.map((event, index) => (
                    <div
                        key={event.id}
                        className="dashboard-event"
                        onClick={() => setSelectedEventIndex(index)}
                    >
                        <span
                            className={`dashboard-event-text ${event.booked || event.gone ? "dashboard-event-booked" : ""}`}
                        >
                            {event.formattedDate}
                        </span>
                    </div>
                ))}
            </div>
        );
    };

    const handleCreateEventChange = (e: FormEvent<HTMLInputElement>) => {
        setFormDate(e.currentTarget.value);
    };

    const closeModal = () => {
        setSelectedEventIndex(-1);
    };

    const resetToast = () => {
        setToastData(null);
    };

    return (
        <div className="dashboard-content">
            {displayEventsList()}

            <form
                className="dashboard-events-create"
                onSubmit={createEvent}
                noValidate
            >
                <input
                    type="date"
                    name="dashboard-events-create-date"
                    value={formDate}
                    onChange={handleCreateEventChange}
                />
                <button type="submit">&#43;</button>
            </form>

            {selectedEventIndex !== -1 &&
                filteredEvents[selectedEventIndex] && (
                    <div
                        className="container-modal"
                        onClick={closeModal}
                        role="dialog"
                        aria-modal="true"
                    >
                        <div
                            className="events-modal-content"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <h1 className="events-modal-text events-modal-title">
                                Événement
                            </h1>
                            <h2 className="events-modal-text events-modal-subtitle">
                                {
                                    filteredEvents[selectedEventIndex]
                                        .translatedWeekday
                                }{" "}
                                {
                                    filteredEvents[selectedEventIndex]
                                        .formattedDate
                                }
                            </h2>

                            {!filteredEvents[selectedEventIndex].gone && (
                                <div className="events-modal-switch-wrapper">
                                    <label className="events-modal-switch">
                                        <input
                                            type="checkbox"
                                            id="events-modal-switch-input"
                                            className="events-modal-switch-input"
                                            checked={
                                                filteredEvents[
                                                    selectedEventIndex
                                                ].booked
                                            }
                                            onChange={() =>
                                                updateEvent(
                                                    filteredEvents[
                                                        selectedEventIndex
                                                    ].id,
                                                    !filteredEvents[
                                                        selectedEventIndex
                                                    ].booked,
                                                )
                                            }
                                        />
                                        <span className="events-modal-switch-slider" />
                                    </label>
                                    <span className="events-modal-switch-label">
                                        {filteredEvents[selectedEventIndex]
                                            .booked
                                            ? "Complet"
                                            : "Disponible"}
                                    </span>
                                </div>
                            )}

                            <button
                                className="events-modal-button"
                                onClick={() =>
                                    deleteEvent(
                                        filteredEvents[selectedEventIndex].id,
                                    )
                                }
                                aria-label="Supprimer l'événement"
                            >
                                <RecycleBin className="events-modal-delete-icon" />{" "}
                                Supprimer
                            </button>
                            <button
                                className="container-modal-close"
                                onClick={closeModal}
                                aria-label="Fermer la fenêtre"
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                )}

            {toastData && (
                <Toast
                    message={toastData.message}
                    type={toastData.type}
                    onClose={resetToast}
                />
            )}
            {isRequestLoading && <Spinner size={SpinnerSize.Small} />}
        </div>
    );
};

export default EventsManager;
