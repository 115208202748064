import { HelmetWrapperProps } from "core/models";
import { FC } from "react";
import { Helmet } from "react-helmet";

const HelmetWrapper: FC<HelmetWrapperProps> = ({ title, description }) => {
    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;

    return (
        <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={canonicalUrl} />
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default HelmetWrapper;
