import { ToastProps } from "core/models";
import { FC, useCallback, useEffect, useState } from "react";
import "styles/components/toast.scss";

const Toast: FC<ToastProps> = ({ type, message, onClose }) => {
    const [isFadingOut, setIsFadingOut] = useState(false);

    const handleClose = useCallback(() => {
        setIsFadingOut(true);
        setTimeout(onClose, 300);
    }, [onClose]);

    useEffect(() => {
        const timer = setTimeout(handleClose, 5000);
        return () => clearTimeout(timer);
    }, [handleClose]);

    return (
        <div
            className={`toast toast-${type} ${isFadingOut ? "toast-fade-out" : ""}`}
            role="alert"
            aria-live="assertive"
        >
            <span>{message}</span>
            <button
                className="toast-close"
                onClick={handleClose}
                aria-label="Close toast"
            >
                &times;
            </button>
        </div>
    );
};

export default Toast;
