import Spinner from "components/spinner";
import Toast from "components/toast";
import {
    DashboardUserData,
    Maybe,
    SpinnerSize,
    ToastData,
    ToastType,
    translatedPermissions,
    UserData,
    UserPermission,
} from "core/models";
import { useAuth } from "providers/authentication";
import { FC, useEffect, useMemo, useState } from "react";
import "styles/pages/manager/users.scss";
import { isUserAllowed } from "utils/permission";
import { logError } from "utils/tools";

const { REACT_APP_API_URL } = process.env;

const UsersManager: FC<{ filter: string }> = ({ filter }) => {
    const [toastData, setToastData] = useState<Maybe<ToastData>>(null);
    const [isListLoading, setIsListLoading] = useState(true);
    const [users, setUsers] = useState<DashboardUserData>([]);
    const { protectedFetch, user } = useAuth();

    useEffect(() => {
        const fetchEvents = async () => {
            setIsListLoading(true);
            try {
                const res = await protectedFetch(`${REACT_APP_API_URL}/users`);
                if (res.ok) {
                    const data: UserData = await res.json();

                    const processed: DashboardUserData = data.map((user) => ({
                        ...user,
                        translatedPermission:
                            translatedPermissions[user.permission],
                    }));

                    setUsers(processed);
                } else {
                    setToastData({
                        type: ToastType.Error,
                        message: "Impossible de récupérer les utilisateurs",
                    });
                }
            } catch (error) {
                logError(
                    `An error occurred while fetching the users: ${error}`,
                );
                setToastData({
                    type: ToastType.Error,
                    message: "Erreur lors de la récupération des utilisateurs",
                });
            } finally {
                setIsListLoading(false);
            }
        };

        fetchEvents();
    }, [protectedFetch]);

    const filteredUsers = useMemo(() => {
        const lowerCaseFilter = filter.toLowerCase();
        return users.filter(
            (user) =>
                user.firstname.toLowerCase().includes(lowerCaseFilter) ||
                user.lastname.toLowerCase().includes(lowerCaseFilter) ||
                user.email.toLowerCase().includes(lowerCaseFilter) ||
                user.permission.toLowerCase().includes(lowerCaseFilter) ||
                user.translatedPermission
                    .toLowerCase()
                    .includes(lowerCaseFilter),
        );
    }, [filter, users]);

    if (!isUserAllowed(user, UserPermission.Administrator)) {
        return null;
    }

    const displayUsersList = () => {
        if (isListLoading) {
            return <Spinner size={SpinnerSize.Medium} />;
        }

        if (filteredUsers.length < 1) {
            return <p className="dashboard-content-title">Aucun utilisateur</p>;
        }

        return (
            <table className="dashboard-users-table">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>E-mail</th>
                        <th>Permission</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredUsers.map((user) => (
                        <tr key={user.id}>
                            <td>
                                {user.firstname} {user.lastname}
                            </td>
                            <td>{user.email}</td>
                            <td>{user.translatedPermission}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    return (
        <div className="dashboard-content">
            {displayUsersList()}

            {toastData && (
                <Toast
                    message={toastData.message}
                    type={toastData.type}
                    onClose={() => setToastData(null)}
                />
            )}
        </div>
    );
};

export default UsersManager;
