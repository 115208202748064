import { AuthContextProps, AuthProviderProps, Maybe, User } from "core/models";
import { createContext, FC, useContext, useState } from "react";
import { logError } from "utils/tools";

const { REACT_APP_API_URL } = process.env;

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<Maybe<User>>(null);

    const refreshToken = async (): Promise<void> => {
        try {
            const res = await fetch(
                `${REACT_APP_API_URL}/authentication/refresh`,
                {
                    method: "POST",
                    credentials: "include",
                },
            );

            if (res.ok) {
                const data = await res.json();

                setIsAuthenticated(true);
                setUser(data);

                return;
            }
        } catch (error) {
            logError(`An error occurred while refreshing the token: ${error}`);
        }

        if (isAuthenticated) {
            setIsAuthenticated(false);
            logout();
        }
    };

    const checkAuthStatus = async (): Promise<boolean> => {
        setIsLoading(true);

        if (!isAuthenticated) {
            await refreshToken();
        }

        setIsLoading(false);

        return isAuthenticated;
    };

    const protectedFetch = async (
        url: string,
        options?: RequestInit,
    ): Promise<Response> => {
        const executeRequest = async (): Promise<Response> => {
            return await fetch(url, {
                ...options,
                credentials: "include",
            });
        };

        let res = await executeRequest();

        if (res.status === 401) {
            await refreshToken();

            if (isAuthenticated) {
                res = await executeRequest();
            }
        }

        return res;
    };

    const login = async (email: string, password: string): Promise<boolean> => {
        try {
            const res = await fetch(
                `${REACT_APP_API_URL}/authentication/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email, password }),
                    credentials: "include",
                },
            );

            if (res.ok) {
                const data = await res.json();

                setIsAuthenticated(true);
                setUser(data);

                return true;
            }
        } catch (error) {
            logError(`An error occurred while logging in: ${error}`);
        }

        return false;
    };

    const logout = async (): Promise<void> => {
        try {
            await fetch(`${REACT_APP_API_URL}/authentication/logout`, {
                method: "POST",
                credentials: "include",
            });
        } catch (error) {
            logError(`An error occurred while logging out: ${error}`);
        } finally {
            setIsAuthenticated(false);
            setUser(null);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                checkAuthStatus,
                protectedFetch,
                login,
                logout,
                user,
                isAuthenticated,
                isLoading,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
};
