import { ReactNode } from "react";

/**
 * @description A type that represents a value that can be null.
 */
export type Maybe<T> = T | null;

/**
 * @description Archive data.
 */
export interface ArchiveData {
    [year: string]: {
        title: string;
        kid?: string;
        count: number;
    };
}

/**
 * @description Contact form data.
 */
export interface ContactFormData {
    name: string;
    email: string;
    subject: string;
    message: string;
}

/**
 * @description Login form data.
 */
export interface LoginFormData {
    email: string;
    password: string;
}

export enum Weekday {
    Friday = "friday",
    Saturday = "saturday",
    Sunday = "sunday",
}

/**
 * @description Event object that represents database data.
 */
export interface Event {
    id: number;
    weekday: Weekday;
    date: string;
    booked: boolean;
    gone: boolean;
    dateObj: Date;
}

/**
 * @description Event object used in the dashboard with additional properties.
 */
export interface DashboardEvent extends Event {
    formattedDate: string;
    translatedWeekday: string;
}

/**
 * @description An array of event objects.
 */
export type EventData = Event[];

/**
 * @description An object that groups event data by weekday.
 */
export type ShowEventData = Record<string, EventData>;

/**
 * @description An array of dashboard event objects.
 */
export type DashboardEventData = DashboardEvent[];

/**
 * @description An type that list the dashboard sections.
 */
export enum DashboardSection {
    Account = "account",
    Events = "events",
    Users = "users",
}

/**
 * @description Authentication context properties.
 */
export interface AuthContextProps {
    checkAuthStatus: () => Promise<boolean>;
    protectedFetch: (url: string, options?: RequestInit) => Promise<Response>;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<void>;
    user: Maybe<User>;
    isAuthenticated: boolean;
    isLoading: boolean;
}

/**
 * @description Authentication provider properties.
 */
export interface AuthProviderProps {
    children: ReactNode;
}

/**
 * @description User properties.
 */
export interface User {
    id: number;
    email: string;
    firstname: string;
    lastname: string;
    permission: UserPermission;
}

export interface DashboardUser extends User {
    translatedPermission: string;
}

/**
 * @description An array of user objects
 */
export type UserData = User[];

/**
 * @description An array of dashboard user objects.
 */
export type DashboardUserData = DashboardUser[];

/**
 * @description The user permission levels.
 */
export enum UserPermission {
    Administrator = "administrator",
    Manager = "manager",
    User = "user",
}

/**
 * @description The hierarchy of the user permission levels.
 */
export const permissionHierarchy = [
    UserPermission.User,
    UserPermission.Manager,
    UserPermission.Administrator,
];

/**
 * @description The translated permission levels.
 */
export const translatedPermissions = {
    administrator: "Administrateur",
    manager: "Gestionnaire",
    user: "Utilisateur",
};

/**
 * @description Theme type different values.
 */
export enum Theme {
    Light = "light",
    Dark = "dark",
}

/**
 * @description Theme context properties.
 */
export interface ThemeContextProps {
    toggleTheme: () => void;
    theme: Theme;
}

/**
 * @description Theme provider properties.
 */
export interface ThemeProviderProps {
    children: ReactNode;
}

/**
 * @description Protected route properties.
 */
export interface ProtectedRouteProps {
    children: ReactNode;
}

/**
 * @description Spinner size different values.
 */
export enum SpinnerSize {
    Small = "small",
    Medium = "medium",
}

/**
 * @description Spinner properties.
 */
export interface SpinnerProps {
    size: SpinnerSize;
}

/**
 * @description Toast type different values.
 */
export enum ToastType {
    Success = "success",
    Error = "error",
}

/**
 * @description Toast data properties.
 */
export interface ToastData {
    message: string;
    type: ToastType;
}

/**
 * @description Toast properties.
 */
export interface ToastProps {
    type: ToastType;
    message: string;
    onClose: () => void;
}

/**
 * @description Helmet wrapper properties.
 */
export interface HelmetWrapperProps {
    title: string;
    description: string;
}

/**
 * @description The content of the form to reset the password from the account manager.
 */
export interface PasswordFormData {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}
