import Spinner from "components/spinner";
import Toast from "components/toast";
import {
    Maybe,
    PasswordFormData,
    SpinnerSize,
    ToastData,
    ToastType,
    translatedPermissions,
    UserPermission,
} from "core/models";
import { useAuth } from "providers/authentication";
import { FC, FormEvent, useState } from "react";
import "styles/pages/manager/account.scss";
import { isUserAllowed } from "utils/permission";
import { logError } from "utils/tools";
import { isPasswordValid } from "utils/validity";

const { REACT_APP_API_URL } = process.env;

const AccountManager: FC = () => {
    const [formData, setFormData] = useState<PasswordFormData>({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
    });
    const [isRequestLoading, setIsRequestLoading] = useState(false);
    const [toastData, setToastData] = useState<Maybe<ToastData>>(null);
    const { protectedFetch, user } = useAuth();

    if (!user) {
        return null;
    }

    const handleChange = (event: FormEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = (): Maybe<string> => {
        if (
            !formData.oldPassword.trim() ||
            !formData.newPassword.trim() ||
            !formData.confirmNewPassword.trim()
        ) {
            return "Tous les champs doivent être remplis.";
        }

        if (!isPasswordValid(formData.oldPassword)) {
            return "Le mot de passe actuel n'est pas valide.";
        }

        if (!isPasswordValid(formData.newPassword)) {
            return "Le mot de passe doit contenir au moins 8 caractères, une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.";
        }

        if (formData.newPassword !== formData.confirmNewPassword) {
            return "Les nouveaux mots de passe ne correspondent pas.";
        }

        return null;
    };

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setIsRequestLoading(true);

        const errorMessage = validateForm();
        if (errorMessage) {
            setToastData({
                type: ToastType.Error,
                message: errorMessage,
            });
            setIsRequestLoading(false);
            return;
        }

        try {
            const res = await protectedFetch(
                `${REACT_APP_API_URL}/users/update-password`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(formData),
                },
            );

            if (res.ok) {
                setToastData({
                    type: ToastType.Success,
                    message: "Mot de passe mis à jour avec succès",
                });
                setFormData({
                    oldPassword: "",
                    newPassword: "",
                    confirmNewPassword: "",
                });
            }
        } catch (error) {
            logError(`An error occurred while updating the password: ${error}`);
            setToastData({
                type: ToastType.Error,
                message: "Erreur lors de la mise à jour du mot de passe",
            });
        } finally {
            setIsRequestLoading(false);
        }
    };

    const sendTestEmail = async () => {
        setIsRequestLoading(true);

        try {
            const res = await protectedFetch(
                `${REACT_APP_API_URL}/health/email`,
                {},
            );

            if (res.ok) {
                setToastData({
                    type: ToastType.Success,
                    message: "E-mail de test envoyé avec succès",
                });
            } else {
                setToastData({
                    type: ToastType.Error,
                    message: "Erreur lors de l'envoi de l'e-mail de test",
                });
            }
        } catch (error) {
            logError(
                `An error occurred while sending the test email: ${error}`,
            );
            setToastData({
                type: ToastType.Error,
                message: "Erreur lors de l'envoi de l'e-mail de test",
            });
        } finally {
            setIsRequestLoading(false);
        }
    };

    return (
        <div className="dashboard-account">
            <div className="dashboard-account-section">
                <label htmlFor="account-email">Identifiant</label>
                <input
                    type="email"
                    value={user.email}
                    id="account-email"
                    disabled
                />
                <label htmlFor="account-permission">Permission</label>
                <input
                    type="text"
                    value={translatedPermissions[user.permission]}
                    id="account-permission"
                    disabled
                />
            </div>

            <div className="dashboard-account-section">
                <form
                    className="dashboard-account-password-section"
                    onSubmit={submitForm}
                    noValidate
                >
                    <label htmlFor="account-old-password">
                        Ancien mot de passe
                    </label>
                    <input
                        type="password"
                        id="account-old-password"
                        name="oldPassword"
                        placeholder="••••••••••••••••••"
                        value={formData.oldPassword}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="account-new-password">
                        Nouveau mot de passe
                    </label>
                    <input
                        type="password"
                        id="account-new-password"
                        name="newPassword"
                        placeholder="••••••••••••••••••"
                        value={formData.newPassword}
                        onChange={handleChange}
                        required
                    />

                    <label htmlFor="account-confirm-new-password">
                        Confirmer le mot de passe
                    </label>
                    <input
                        type="password"
                        id="account-confirm-new-password"
                        name="confirmNewPassword"
                        placeholder="••••••••••••••••••"
                        value={formData.confirmNewPassword}
                        onChange={handleChange}
                        required
                    />

                    <button
                        className="container-button container-small-button container-sad-button"
                        disabled={isRequestLoading}
                    >
                        Modifier le mot de passe
                    </button>
                </form>
            </div>

            {isUserAllowed(user, UserPermission.Administrator) && (
                <div className="dashboard-account-section dashboard-account-section-test-email">
                    <label className="dashboard-account-test-email-label">
                        Envoyer un e-mail de test
                    </label>
                    <button
                        className="dashboard-account-test-email-action"
                        onClick={sendTestEmail}
                        disabled={isRequestLoading}
                    />
                </div>
            )}

            {toastData && (
                <Toast
                    message={toastData.message}
                    type={toastData.type}
                    onClose={() => setToastData(null)}
                />
            )}
            {isRequestLoading && <Spinner size={SpinnerSize.Small} />}
        </div>
    );
};

export default AccountManager;
