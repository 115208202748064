import HelmetWrapper from "components/helmet-wrapper";
import { DashboardSection, Maybe, UserPermission } from "core/models";
import AccountManager from "pages/manager/account";
import EventsManager from "pages/manager/events";
import UsersManager from "pages/manager/users";
import { useAuth } from "providers/authentication";
import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "styles/pages/dashboard.scss";
import { isUserAllowed } from "utils/permission";

const Dashboard: FC = () => {
    const [section, setSection] = useState<DashboardSection>(
        DashboardSection.Account,
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState("");

    const { user, logout } = useAuth();

    const sections = useMemo(
        () => ({
            account: <AccountManager />,
            events: <EventsManager filter={filter} />,
            users: <UsersManager filter={filter} />,
        }),
        [filter],
    );

    useEffect(() => {
        const urlSection: Maybe<string> = searchParams.get("section");
        if (urlSection && Object.keys(sections).includes(urlSection)) {
            setSection(urlSection as DashboardSection);
        }
    }, [searchParams, sections]);

    const handleSectionChange = (newSection: DashboardSection) => {
        setSearchParams({
            section: newSection,
        });
        setSection(newSection);
    };

    if (!user) {
        return null;
    }

    return (
        <div className="container">
            <HelmetWrapper title="Tableau de Bord" description="" />

            <h1 className="container-title">Tableau de Bord</h1>
            <p className="container-description">
                Bonjour {user.firstname} {user.lastname} !
                <br />
                Gérez facilement vos informations et vos événements.
            </p>

            <hr className="container-separator" />

            <div className="dashboard-header">
                <div className="container-select-wrapper container-small-selecter">
                    <select
                        className="container-selecter"
                        name="dashboard-selecter-section"
                        value={section}
                        onChange={(e) =>
                            handleSectionChange(
                                e.target.value as DashboardSection,
                            )
                        }
                        aria-label="Changer de section"
                    >
                        <option value={DashboardSection.Account}>
                            Mon compte
                        </option>
                        <option
                            value={DashboardSection.Events}
                            hidden={
                                !isUserAllowed(user, UserPermission.Manager)
                            }
                        >
                            Événements
                        </option>
                        <option
                            value={DashboardSection.Users}
                            hidden={
                                !isUserAllowed(
                                    user,
                                    UserPermission.Administrator,
                                )
                            }
                        >
                            Utilisateurs
                        </option>
                    </select>
                </div>
                <input
                    className="dashboard-search-bar"
                    type="search"
                    name="dashboard-search-bar"
                    placeholder="Taper pour rechercher..."
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                    aria-label="Rechercher une donnée dans la section actuelle"
                />
            </div>

            <hr className="container-separator" />

            {sections[section]}

            <hr className="container-separator" />

            <button
                className="container-button container-small-button"
                onClick={logout}
            >
                Se déconnecter
            </button>
        </div>
    );
};

export default Dashboard;
