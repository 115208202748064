import { ReactComponent as NextArrow } from "assets/icons/next-arrow.svg";
import { ReactComponent as PrevArrow } from "assets/icons/prev-arrow.svg";
import HelmetWrapper from "components/helmet-wrapper";
import { ArchiveData } from "core/models";
import archivesFile from "data/archives.json";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "styles/pages/archives.scss";

const { REACT_APP_IMAGE_URL } = process.env;

const archivesData: ArchiveData = archivesFile;

const Archives: FC = () => {
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(-1);
    const [searchParams, setSearchParams] = useSearchParams();
    const [year, setYear] = useState<string>("");

    useEffect(() => {
        document.body.style.overflow =
            selectedImageIndex !== -1 ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [selectedImageIndex]);

    useEffect(() => {
        const urlYear = searchParams.get("year");
        if (urlYear && archivesData[urlYear]) {
            setYear(urlYear);
        }
    }, [searchParams]);

    const handleYearChange = (newYear: string) => {
        setSearchParams({
            year: newYear,
        });
        setYear(newYear);
    };

    const closeModal = () => {
        setSelectedImageIndex(-1);
    };

    const showNextImage = () => {
        if (selectedImageIndex !== -1 && archivesData[year]) {
            const nextIndex =
                (selectedImageIndex + 1) % archivesData[year].count;
            setSelectedImageIndex(nextIndex);
        }
    };

    const showPreviousImage = () => {
        if (selectedImageIndex !== -1 && archivesData[year]) {
            const prevIndex =
                (selectedImageIndex - 1 + archivesData[year].count) %
                archivesData[year].count;
            setSelectedImageIndex(prevIndex);
        }
    };

    return (
        <div className="container">
            <HelmetWrapper
                title={`Archives ${year} - Logeler Finka`}
                description="Les archives permettent de consulter les photos des précédentes représentations."
            />

            <h1 className="container-title">Archives</h1>
            <p className="container-description">
                Parcourez les archives de nos précédentes représentations et
                revivez les moments forts en images.
            </p>

            <div className="container-select-wrapper container-large-selecter">
                <select
                    className="container-selecter"
                    name="archives-selecter"
                    value={year}
                    onChange={(e) => handleYearChange(e.target.value)}
                >
                    <option value="">Sélectionner une année</option>
                    {Object.keys(archivesData).map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>

            <hr className="container-separator"></hr>

            {archivesData[year] ? (
                <div>
                    <p className="archives-title">
                        Cette année nous avons interprété
                    </p>
                    <p className="archives-subtitle">
                        {archivesData[year].title}
                    </p>
                    {archivesData[year].kid && (
                        <div className="archives-kid">
                            <p className="archives-title">
                                Avec la participation des enfants dans
                            </p>
                            <p className="archives-subtitle">
                                {archivesData[year].kid}
                            </p>
                        </div>
                    )}
                    <div className="archives-images">
                        {Array.from(
                            { length: archivesData[year].count },
                            (_, index) => (
                                <img
                                    loading="lazy"
                                    src={`${REACT_APP_IMAGE_URL}/${year}-${index + 1}.webp`}
                                    alt={`${year}-${index + 1}`}
                                    className="archives-image"
                                    onClick={() => setSelectedImageIndex(index)}
                                    key={index}
                                />
                            ),
                        )}
                    </div>
                </div>
            ) : (
                <p className="archives-title">
                    Aucune information disponible pour cette année.
                </p>
            )}

            {selectedImageIndex !== -1 && archivesData[year] && (
                <div
                    className="container-modal"
                    onClick={closeModal}
                    role="dialog"
                    aria-modal="true"
                >
                    <div
                        className="archives-modal-content"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            className={`archives-modal-arrow ${selectedImageIndex <= 0 && "archives-modal-arrow-hidden"}`}
                            onClick={showPreviousImage}
                        >
                            <PrevArrow className="archives-modal-arrow-icon archives-modal-prev-arrow-icon" />
                        </button>

                        <img
                            src={`${REACT_APP_IMAGE_URL}/${year}-${selectedImageIndex + 1}.webp`}
                            alt={`${year}-${selectedImageIndex + 1}`}
                            className="archives-modal-image"
                        />

                        <button
                            className={`archives-modal-arrow ${selectedImageIndex >= archivesData[year].count - 1 && "archives-modal-arrow-hidden"}`}
                            onClick={showNextImage}
                        >
                            <NextArrow className="archives-modal-arrow-icon archives-modal-next-arrow-icon" />
                        </button>

                        <button
                            className="container-modal-close"
                            onClick={closeModal}
                        >
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Archives;
