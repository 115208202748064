import HelmetWrapper from "components/helmet-wrapper";
import { FC } from "react";
import "styles/pages/terms.scss";

const Terms: FC = () => {
    return (
        <div className="container">
            <HelmetWrapper
                title="Mentions Légales - Logeler Finka"
                description="Consultez les mentions légales de notre site internet. Informations sur les droits des utilisateurs, la propriété intellectuelle et les conditions générales d'utilisation."
            />

            <h1 className="container-title">Mentions Légales</h1>
            <h2 className="terms-subtitle">
                Présentation de notre site internet
            </h2>
            <p className="container-text">
                Conformément à la loi n° 2004-2005 du 21 juin 2004 pour la
                confiance dans l'économie numérique, il est précisé aux
                utilisateurs du site internet{" "}
                <a
                    className="container-link"
                    href="https://www.logelerfinka.fr"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Logeler Finka
                </a>{" "}
                l'identité des différents intervenants dans le cadre de sa
                réalisation et de son suivi. Notre site internet met à
                disposition du public des informations sur les dates, les prix,
                les représentations et autres activités liées à l'association
                Logeler Finka.
            </p>
            <p className="container-text">
                <strong>Propriétaire</strong> : Association Logeler Finka - 28
                Rue Grendel, 68280 Logelheim
                <br />
                <strong>Éditeur</strong> :{" "}
                <a
                    href="mailto:mael.bertocchi@gmail.com"
                    className="container-link"
                >
                    Maël Bertocchi
                </a>{" "}
                - Développeur indépendant
                <br />
                <strong>Responsable publication</strong> : Colette Schwenger -
                Présidente de Logeler Finka
                <br />
                <strong>Hébergeur</strong> : OVH - 2 rue Kellermann, 59100
                Roubaix
                <br />
            </p>
            <h2 className="terms-subtitle">
                Conditions générales d'utilisation du site
            </h2>
            <p className="container-text">
                En utilisant notre site internet, vous acceptez pleinement et
                entièrement les conditions générales d'utilisation précisées
                dans nos mentions légales.
            </p>
            <h2 className="terms-subtitle">
                Modification des mentions légales
            </h2>
            <p className="container-text">
                Nous nous réservons le droit de modifier ces mentions légales à
                tout moment. Les utilisateurs sont invités à consulter
                régulièrement cette page pour prendre connaissance des
                éventuelles modifications.
            </p>
            <h2 className="terms-subtitle">Propriété intellectuelle</h2>
            <p className="container-text">
                La structure du site mais aussi les textes, graphiques, images,
                photographies, sons, vidéos qui le composent sont la propriété
                de l'association Logeler Finka et sont protégés comme tels par
                les lois en vigueur au titre de la propriété intellectuelle.
                Toute reproduction, représentation, modification, publication,
                totale ou partielle, du site ou de son contenu, est interdite
                sans autorisation préalable de l'association Logeler Finka.
            </p>
            <h2 className="terms-subtitle">Droits des utilisateurs</h2>
            <p className="container-text">
                Nous collectons des données personnelles lorsque vous remplissez
                un formulaire de contact. Les informations collectées incluent
                votre nom, courriel et autres informations nécessaires pour
                traiter votre demande. Nous nous engageons à ne céder en aucun
                cas les informations concernant les utilisateurs, de quelque
                façon qu'il soit.
            </p>
            <p className="container-text">
                Vous avez le droit d'accéder, de rectifier ou de supprimer vos
                données personnelles. Pour exercer ces droits, veuillez nous
                contacter à l'adresse{" "}
                <a
                    href="mailto:logelerfinka@gmail.com"
                    className="container-link"
                >
                    logelerfinka@gmail.com
                </a>
                .
            </p>
        </div>
    );
};

export default Terms;
