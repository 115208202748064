/**
 * @function isEmailValid
 *
 * @description Verify if the email is valid. The email must contain at least
 * one character before and after the at sign, and a domain name after the last dot.
 *
 * @param str The email to verify.
 * @returns Whether the email is valid.
 */
export function isEmailValid(str: string): boolean {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(str);
}

/**
 * @function isNameValid
 *
 * @description Verify if the name is valid. The name must contain only letters,
 * spaces, and hyphens.
 *
 * @param str The name to verify.
 * @returns Whether the name is valid.
 */
export function isNameValid(str: string): boolean {
    const nameRegex = /^[a-zA-Z-'À-ÖØ-öø-ÿ ]{2,}$/;
    return nameRegex.test(str);
}

/**
 * @function isPasswordValid
 *
 * @description Verify if the password is valid. The password must contain at
 * least one lowercase letter, one uppercase letter, one number, one special
 * character, and be at least 8 characters long.
 *
 * @param str The password to verify.
 * @returns Whether the password is valid.
 */
export function isPasswordValid(str: string): boolean {
    const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;
    return passwordRegex.test(str);
}
