import HelmetWrapper from "components/helmet-wrapper";
import Spinner from "components/spinner";
import Toast from "components/toast";
import {
    ContactFormData,
    Maybe,
    SpinnerSize,
    ToastData,
    ToastType,
} from "core/models";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import "styles/pages/contact.scss";
import { logError } from "utils/tools";
import { isEmailValid, isNameValid } from "utils/validity";

const { REACT_APP_API_URL } = process.env;

const messageLimit = 500;
const subjectLimit = 50;

const Contact: FC = () => {
    const [formData, setFormData] = useState<ContactFormData>({
        name: "",
        email: "",
        subject: "",
        message: "",
    });
    const [toastData, setToastData] = useState<Maybe<ToastData>>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const { name, value } = event.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateForm = (): Maybe<string> => {
        if (
            !formData.name.trim() ||
            !formData.email.trim() ||
            !formData.subject.trim() ||
            !formData.message.trim()
        ) {
            return "Tous les champs doivent être remplis";
        }

        if (!isNameValid(formData.name)) {
            return "Le nom n'est pas valide";
        }

        if (!isEmailValid(formData.email)) {
            return "L'adresse email n'est pas valide";
        }

        if (formData.subject.length > subjectLimit) {
            return `Le sujet ne doit pas dépasser ${subjectLimit} caractères`;
        }

        if (formData.message.length > messageLimit) {
            return `Le message ne doit pas dépasser ${messageLimit} caractères`;
        }
        return null;
    };

    const submitForm = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        const errorMessage = validateForm();
        if (errorMessage) {
            setToastData({
                type: ToastType.Error,
                message: errorMessage,
            });
            setIsLoading(false);
            return;
        }

        try {
            const res = await fetch(`${REACT_APP_API_URL}/contact`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (res.ok) {
                setToastData({
                    type: ToastType.Success,
                    message: "Votre message a bien été envoyé",
                });
                setFormData({ name: "", email: "", subject: "", message: "" });
            } else {
                setToastData({
                    type: ToastType.Error,
                    message:
                        res.status !== 429
                            ? "Une erreur est survenue lors de l'envoi de votre message"
                            : "Veuillez attendre avant de renvoyer un message",
                });
            }
        } catch (error) {
            logError(
                `An error occurred while sending the contact form: ${error}`,
            );
            setToastData({
                type: ToastType.Error,
                message:
                    "Une erreur est survenue lors de l'envoi du formulaire de contact",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="container">
            <HelmetWrapper
                title="Contact - Logeler Finka"
                description="Contactez-nous pour toute question."
            />

            <h1 className="container-title">Nous Contacter</h1>
            <p className="container-description">
                Vous avez une question ou besoin de plus d'informations ?
                <br />
                Remplissez le formulaire ci-dessous, et nous reviendrons vers
                vous dans les plus brefs délais !
            </p>

            <form className="contact-form" noValidate onSubmit={submitForm}>
                <label className="contact-form-label" htmlFor="name">
                    Nom <span className="contact-form-required">*</span>
                </label>
                <input
                    className="contact-form-input"
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleChange}
                />

                <label className="contact-form-label" htmlFor="email">
                    Email <span className="contact-form-required">*</span>
                </label>
                <input
                    className="contact-form-input"
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                />

                <label className="contact-form-label" htmlFor="subject">
                    Sujet <span className="contact-form-required">*</span>
                </label>
                <input
                    className="contact-form-input"
                    type="text"
                    name="subject"
                    id="subject"
                    value={formData.subject}
                    onChange={handleChange}
                />
                <div
                    className={`contact-form-charcount ${formData.subject.length > subjectLimit ? "contact-form-charcount-exceeded" : ""}`}
                >
                    {formData.subject.length}/{subjectLimit} caractères
                </div>

                <label className="contact-form-label" htmlFor="message">
                    Message <span className="contact-form-required">*</span>
                </label>
                <textarea
                    className="contact-form-textarea"
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                />
                <div
                    className={`contact-form-charcount ${formData.message.length > messageLimit ? "contact-form-charcount-exceeded" : ""}`}
                >
                    {formData.message.length}/{messageLimit} caractères
                </div>

                <div className="contact-form-separator" />

                <button
                    className="container-button container-large-button"
                    type="submit"
                    disabled={isLoading}
                >
                    Envoyer
                </button>
            </form>

            {toastData && (
                <Toast
                    message={toastData.message}
                    type={toastData.type}
                    onClose={() => setToastData(null)}
                />
            )}
            {isLoading && <Spinner size={SpinnerSize.Small} />}
        </div>
    );
};

export default Contact;
